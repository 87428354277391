import { redirect } from 'next/navigation';  // for app router
// or
import { useEffect } from 'react';
import { useRouter } from 'next/router';  // for pages router


export default function Custom404() {
  const router = useRouter();
  
  useEffect(() => {
    router.replace('/landing');
  }, []);

  return null; // Component will redirect before rendering
}